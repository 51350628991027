import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// TODO: not used

export default class extends Controller {
  static targets = ["page", "group", "thanksurl", "standardthanks"];
  static values = {id: Number}

  toggleThanksUrl(e) {
    this.thanksurlTarget.classList.toggle('hidden')
    this.standardthanksTarget.classList.toggle('hidden')
  }

  async changePage(e) {
    this.pageTarget.value = e.params.page
    this.groupTargets.forEach(element => {
      if (element.id !== e.params.page) {
        element.parentElement.toggleAttribute('open', false)
      }
    })
    const response = await get(`/capforms/${this.idValue}/change_page?page=${e.params.page}`, {
      responseKind: "turbo-stream",
    })

    if (response.ok) { console.log(response) }
  }
}
